html {
  height: 100%;
  margin: 0 auto;
}

body {
  width: 100%;
  font-family: "微軟正黑體", "メイリオ", "ＭＳ Ｐゴシック", Osaka, "ヒラギノ角ゴ Pro W3", 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 20px;
  color: #404040;
  position: relative;
  display: block;
  height: 100%;
  background:#666666;
  overflow-x: hidden; 
}

.app-wrapper {
  width:100%;
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 15px 0 15px;
  background:#ffffff;
}

.app-wrapper>div{
  min-height: 100%;
  text-align:center;
}

.app-wrapper .app-contents {
  min-height: 100%;
  text-align:center;
}

/* desktop */
@media screen and (min-width:768px) {
  .app-wrapper {
    background-color: #ffffff;
    display: table;
    width: 768px;
    margin: 0 auto;
    padding:15px 0 15px;
  }
  .star-sz {
    font-size: 20px;
    height:30px;
    width:30px;
  }
}

.star-sz{
  font-size: 20px;
  height:30px;
  width:30px;
}

.star {
  border-radius:50%;
  background :red;
  color:yellow;
  display: inline-block;
  padding-top:5px;
  padding-left:2px;
}

.star-s{
  border-radius:50%;
  background :orange;
  color:red;
  display: inline-block;
  text-align:center;
  padding-top:6px;
}

.star-table .row{
  /* padding: 0; */
}
.star-table .col{
  /* padding:5px 8px; */
}

.star-table td{
  /* padding: 10px 5px; */
}

footer{


  margin-top:20px;
  margin-bottom: 20px;
  text-align:center;
  font-size:0.8em;
  color:gray;
  width:100%;

}